"use client";

import React from "react";
import { Toaster } from "@irbano/react-components/dist/components/ui/toast";
import { TRPCReactProvider } from "app/_trpc/client";
import { IntercomProvider } from "react-use-intercom";

export type ClientProvidersProps = {
  children: React.ReactNode;
};

export const ClientProviders = ({ children }: ClientProvidersProps) => {
  return (
    <>
      <IntercomProvider
        appId={process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID as string}
        shouldInitialize={!!process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID}
        autoBoot={true}
        initializeDelay={2000}
      >
        <TRPCReactProvider>{children}</TRPCReactProvider>
      </IntercomProvider>

      <Toaster />
    </>
  );
};
